export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID'
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_COMP = 'ADD_TO_COMP'
export const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT'
export const UPDATE_CART_PRODUCT_DETAILS = 'UPDATE_CART_PRODUCT_DETAILS'
export const GET_CART_BILLING = 'GET_CART_BILLING'
export const SET_CC_PATH = 'SET_CC_PATH'
export const REMOVE_CART = 'REMOVE_CART'
export const DEL_COMPARE = 'DEL_COMPARE'
export const CLEAR_CART = 'CLEAR_CART'
export const ADD_TO_FAV = 'ADD_TO_FAV'
export const REMOVE_FAV = 'REMOVE_FAV'
export const GET_CARTS = 'GET_CARTS'
export const GET_CART_SUMMARY = 'GET_CART_SUMMARY'
export const GET_CART = 'GET_CART'
export const GET_CART_TOTALS = 'GET_CART_TOTALS'
export const REMOVE_CART_TOTALS_PLACEHOLDERS = 'REMOVE_CART_TOTALS_PLACEHOLDERS'
export const GET_CART_SHIPPING_ADDRESS = 'GET_CART_SHIPPING_ADDRESS'
export const GET_SHIPPING_QUOTES = 'GET_SHIPPING_QUOTES'
export const GET_SHIPPING_QUOTE = 'GET_SHIPPING_QUOTE'
export const GET_EXISTING_QUOTE = 'GET_EXISTING_QUOTE'
export const GET_COUPON_CODE = 'GET_COUPON_CODE'
export const SET_CART_SHIPPING_ADDRESS_MODAL = 'SET_CART_SHIPPING_ADDRESS_MODAL'
export const GET_SAVED_CARTS_WITH_ITEMS = 'GET_SAVED_CARTS_WITH_ITEMS'
export const SET_CHECKOUT_STEPS_STATUS = 'SET_CHECKOUT_STEPS_STATUS'
export const CLEAR_CHECKOUT_STEPS_STATUS = 'CLEAR_CHECKOUT_STEPS_STATUS'
export const CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE =
  'CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE'
export const INITIALIZE_PRODUCT_STATE = 'INITIALIZE_PRODUCT_STATE'
export const AUTH_CREDIT_CARD = 'AUTH_CREDIT_CARD'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SET_CREDIT_CARD_PAYMENT = 'SET_CREDIT_CARD_PAYMENT'
export const UPDATE_PRODUCT_IN_CART = 'UPDATE_PRODUCT_IN_CART'
export const PURGE_OLD_CART_DATA = 'PURGE_OLD_CART_DATA'
export const CLEAR_SHIPPING_QUOTES = 'CLEAR_SHIPPING_QUOTES'
export const CLEAR_SUBMIT_ORDER_RESPONSE = 'CLEAR_SUBMIT_ORDER_RESPONSE'
export const SET_CART_UPDATING = 'SET_CART_UPDATING'
export const SET_CART_HAS_CARRIER_QUOTES = 'SET_CART_HAS_CARRIER_QUOTES'
export const SET_CART_HAS_VALID_CARRIER = 'SET_CART_HAS_VALID_CARRIER'
export const SET_PAGE = 'SET_PAGE'
export const SET_PAGINATION = 'SET_PAGINATION'
export const GET_CART_RECOMMENDED_PRODUCTS = 'GET_CART_RECOMMENDED_PRODUCTS'
export const SET_CART_FILTER = 'SET_CART_FILTER'
export const SET_PRODUCTS_SEO = 'SET_PRODUCTS_SEO'
export const SET_PRODUCT_URL = 'SET_PRODUCT_URL'
