import { useLocation, useParams } from 'react-router-dom'
import HeaderNavBar from './HeaderNavBar'
import LogoHeader from './LogoHeader'
import { isHideGlobalHeaderPath, isProline } from '../../../helpers'
import { useEffect } from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Helmet } from 'react-helmet'
import {
  DEFAULT_PL_TITLE,
  DEFAULT_WS_TITLE,
} from '../../../constants/constants'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { productKeys } from '../../../queries/products/keyFactory'
import { ICustomerData, IUserProfile } from '../../../queries/user/types'
import { IIadvizeCustomData } from '../../../context/site/types'
import { useProductContext } from '../../../context/products/ProductProvider'
import { IProductData } from '../../../context/products/types'

const Header = () => {
  const location = useLocation()

  const {
    setMainHeaderDivHeight,
    setMobileMainHeaderDivHeight,
    getFlashSaleBanners,
    getPageSeo,
    setIdzCustomData,
    mainHeaderDivHeight,
    mobileMainHeaderDivHeight,
    isDownForMaintenance,
    pageSeo,
    idzCustomData,
  } = useSiteContext()

  const queryClient = useQueryClient()
  const userProfile = queryClient.getQueryData(
    userKeys.userProfile(),
  ) as IUserProfile
  const customerData = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  const { productUrl } = useProductContext()

  useEffect(() => {
    getFlashSaleBanners()
  }, [])

  useEffect(() => {
    if (location.pathname) {
      getPageSeo(location.pathname)
    }
    getFlashSaleBanners()
  }, [location.pathname])

  useEffect(() => {
    if (userProfile?.customers_id && customerData?.customers_id) {
      const customData = {
        ...idzCustomData,
        customersId: userProfile.customers_id.toString(),
        cust_email: userProfile.customers_email_address ?? '',
        priceLevel: customerData.price_levels_id.toString() ?? '16',
        cust_name:
          (userProfile?.customers_firstname
            ? userProfile.customers_firstname
            : '') +
          ' ' +
          (userProfile?.customers_lastname
            ? userProfile.customers_lastname
            : ''),
        cust_firstname: userProfile?.customers_firstname ?? '',
      }

      window.idzCustomData = customData
      setIdzCustomData(customData)

      window.iAdvizeInterface ||= window.iAdvizeInterface
      window.iAdvizeInterface.push(function (iAdvize: any) {
        iAdvize.navigate('')
      })

      console.log('handleIadvizeEffect header (user/customer)', window.idzCustomData)
    }
  }, [userProfile, customerData])

  useEffect(() => {
    // Separated out to allow for async fetchQuery() call
    const handleProductData = async () => {
      if (productUrl) {
        const productData = await queryClient.fetchQuery(
          productKeys.product(productUrl),
        ) as IProductData

        if (productData) {

          // Set the iAdvize config based on the proline status
          if (!isProline()) {
            window.iAdvizeInterface.config = {
              sid: 8948,
              lang: 'en',
            }
          }
          else {
            window.iAdvizeInterface.config = {
              sid: 8961,
              lang: 'en',
              theme: 'proline',
            }
          }

          const customData = {
            ...idzCustomData,
            product_id: productData?.products_id.toString(),
            model: productData?.products_model,
          }
          window.idzCustomData = customData
          setIdzCustomData(customData)

          window.iAdvizeInterface ||= window.iAdvizeInterface
          window.iAdvizeInterface.push(function (iAdvize: any) {
            iAdvize.navigate('')
          })

          // Load and run the iAdvize script
          const iadvize_script = document.createElement('script')
          iadvize_script.src= "//halc.iadvize.com/iadvize.js"
          iadvize_script.async = true
          document.body.appendChild(iadvize_script)

          console.log('handleIadvizeEffect header (product)', window.idzCustomData)
        }
      }
    }

    // Call the function defined above
    handleProductData()
  }, [productUrl])

  window.onresize = () => {
    setMainHeaderDivHeight(mainHeaderDivHeight)
    setMobileMainHeaderDivHeight(mobileMainHeaderDivHeight)
  }

  const title = isProline() ? DEFAULT_PL_TITLE : DEFAULT_WS_TITLE

  return (
    <>
      <Helmet>
        {pageSeo?.id && pageSeo?.page_title && (
          <title>
            {title} - {pageSeo?.page_title}
          </title>
        )}
        {pageSeo?.id && pageSeo?.seo_meta && (
          <meta name="description" content={pageSeo?.seo_meta} />
        )}
        <meta
          name="iadvizeCustomersId"
          id="iadvizeCustomersId"
          content={
            userProfile?.customers_id ? userProfile.customers_id.toString() : ''
          }
        />
        <meta
          name="iadvizeCustomersName"
          id="iadvizeCustomersName"
          content={
            (userProfile?.customers_firstname
              ? userProfile.customers_firstname
              : '') +
            ' ' +
            (userProfile?.customers_lastname
              ? userProfile.customers_lastname
              : '')
          }
        />
        <meta
          name="iadvizeCustomersEmailAddress"
          id="iadvizeCustomersEmailAddress"
          content={
            userProfile?.customers_email_address
              ? userProfile.customers_email_address
              : ''
          }
        />
        <meta
          name="iadvizePriceLevelsId"
          id="iadvizePriceLevelsId"
          content={
            customerData?.price_levels_id
              ? customerData.price_levels_id.toString()
              : '16'
          }
        />
        <meta name="iadvizeTransactionId" content="" />
      </Helmet>
      {isHideGlobalHeaderPath(location.pathname) || isDownForMaintenance ? (
        <LogoHeader linkToHome={!isDownForMaintenance} />
      ) : (
        <HeaderNavBar />
      )}
    </>
  )
}

export default Header
